<template>
  <v-app>
    <mobiledisplay class="hidden-md-and-up" />
    <mediumdisplay class="hidden-sm-and-down" />
      <v-content>
          <router-view/>
      </v-content>
  </v-app>
</template>
<script src="https://js.stripe.com/v3/"></script>

<script>
import mediumdisplay from "./components/mediumdisplay.vue";
import mobiledisplay from "./components/mobiledisplay.vue";
import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";

export default {
  name: "vue",
  components: {
    mediumdisplay,
    mobiledisplay,
  },
  data() {
    return {
      web: 1,
    };
  },

};
</script>
