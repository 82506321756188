import Vue from "vue";
import router from "./router";
import app from "./App";
import "./assets/pgstylesnew.css";
import "vuetify/dist/vuetify.min.css";
import * as VueGoogleMaps from "vue2-google-maps";
import vuetify from "./plugins/vuetify";
import VueYouTubeEmbed from "vue-youtube-embed";
import VueMeta from 'vue-meta';
import VueAnalytics from 'vue-analytics'

Vue.use(VueYouTubeEmbed);
Vue.use(VueMeta);
//if (process.env.NODE_ENV != "development") {
  Vue.use(VueAnalytics, {
    id: "UA-24387918-1",
    router
  });
  gtag('config', 'AW-976434288');
//} else {
  //debugger;
//  alert(process.env.NODE_ENV);
//}

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDDM4qYyk9DBnC8sY5M_UuEyT3FsCsjswk",
    libraries: "places", // necessary for places input
  },
});

new Vue({
  render: (h) => h(app),
  router,
  data() {
    return {
      uid: "",
      opcode: "",
      sitekey: "6Le2NjsUAAAAAK--qJl6GNmHzDRdDEul9aP8wiWi",
      seclev: 0,
      showlogo: 1,
      tmpordno: "",
      shopopen: "T",
      noitems: 0,
      ordid: 0,
      orderno: "",
      preord: false,
      cono: "",
      var1: "",
      webinfo1: "",
      vword: false,
      execsql: "https://www.pastagallery.com.au/common/execsql.php",
      execsqlparam: "https://www.pastagallery.com.au/common/execsqlparam.php",
      query: "https://www.pastagallery.com.au/common/sqlquery.php",
      queryparam: "https://www.pastagallery.com.au/common/sqlqueryparam.php",
      execsqlcrm: "https://www.pastagallery.com.au/common/execsqlcrm.php",
      querycrm: "https://www.pastagallery.com.au/common/sqlquerycrm.php",
      common: "https://www.pastagallery.com.au/common",
      classes: "https://www.pastagallery.com.au/classes",
      PDF: "https://www.pastagallery.com.au/PDFDocuments",
      rootdir: "https://www.pastagallery.com.au",
      cust: {
        name: "",
        delmode: "D",
        clickcollect: "0",
        address1: "",
        address2: "",
        suburb: "",
        postcode: "",
        vouchid: "",
        email: "",
        password: "",
        suburbsel: [],
        phone: "",
        specinstr: "",
        agree: false,
        accno: "",
        dte: "",
      },
    };
  },

  vuetify,

  methods: {
    setUid(uid) {
      this.uid = uid;
    },
    setSeclev(seclev) {
      this.seclev = seclev;
    },
    setOpcode(opcode) {
      this.opcode = opcode;
    },
    currencyFormat(num) {
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    intFormat(num) {
      return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
  },
}).$mount("#app");

