<template>
    <v-container fluid class="pa-0">
      <v-navigation-drawer v-model="drawer" app dark left class="white">
        <template v-slot:prepend>
          <v-list-item key="1">
            <v-list-item-icon>
              <v-icon @click="drawer = !drawer">view_headline</v-icon>
              <v-list-item-content>
                <v-list-item-title class="white ">Menu</v-list-item-title>
              </v-list-item-content>
            </v-list-item-icon>
          </v-list-item>
        </template>
        <v-list nav dense>
          <v-list-item-title
            class="black--text text-uppercase font-weight-light subtitle-2 pa-1"
            @click="handleSelect('ravioli')"
            >Ravioli</v-list-item-title
          >
          <v-list-item-title
            class="black--text text-uppercase font-weight-light subtitle-2 pa-1"
            @click="handleSelect('freshpasta')"
            >Pasta</v-list-item-title
          >
          <v-list-item-title
            class="black--text text-uppercase font-weight-light subtitle-2 pa-1"
            @click="handleSelect('other')"
            >Bundles / Bakes / Mates</v-list-item-title
          >
          <v-list-item-title
            class="black--text text-uppercase font-weight-light subtitle-2 pa-1"
            @click="handleSelect('wholesale')"
            >Wholesale</v-list-item-title
          >
          <v-list-item-title
            class="black--text text-uppercase font-weight-light subtitle-2 pa-1"
            @click="handleSelect('stockists')"
            >Stockists</v-list-item-title
          >
          <v-list-item-title
            class="black--text text-uppercase font-weight-light subtitle-2 pa-1"
            @click="handleSelect('contactus')"
            >Contact Us</v-list-item-title
          >
        </v-list>
      </v-navigation-drawer>
      <v-app-bar
        dense
        app
        scroll-target="#sheet1"
        color="white"
        flat
        fluid
        style="z-index: 200"
      >
        <v-toolbar-items>
          <v-btn @click="drawer = true" color="white" text class="pa-0 small">
            <v-icon color="black">view_headline</v-icon>
          </v-btn>
          <v-spacer />
          <v-btn
            text
            small
            class="black--text font-weight-normal caption pa-2"
            @click="handleSelect('orderonline')"
            >Shop</v-btn
          >
        </v-toolbar-items>
        <v-spacer />
        <v-img
          max-width="100"
          contain
          @click="handleSelect('home')"
          :src="logoimg"
          height="50"
        ></v-img>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            text
            small
            class="black--text font-weight-normal caption pa-2"
            @click="handleSelect('aboutus')"
            >About</v-btn
          >
        </v-toolbar-items>
        <v-spacer />
        <v-btn
          icon
          class="mr-1"
          color="black"
          @click="
            $root.preord = true;
            handleSelect('orderonline');
          "
        >
          <v-icon>mdi-cart</v-icon>
          <v-badge
            color="grey"
            :content="$root.noitems"
            v-if="$root.noitems > 0"
          ></v-badge>
        </v-btn>
      </v-app-bar>
    </v-container>
</template>

<script>
export default {
  name: "mobiledisp",
  data() {
    return {
      drawer: false,
      mobile: true,
      dlglogon: false,
      logoimg: require("@/assets/logo.jpg"),
      logicon: true,
      activeIndex: "1",
      activeIndex2: "1",
      seclev: 0,
      on: false,
      offsetTop: 0,
      web: 1,
    };
  },
  methods: {
    // onScroll(e) {
    //   this.offsetTop = e.target.scrollTop;
    //   if (this.offsetTop > 1000) {
    //     this.$root.showlogo = 0;
    //   } else {
    //     this.$root.showlogo = 1;
    //   }
    // },
    clsdialog() {
      this.dlglogon = false;
    },
    login() {
      this.dlglogon = true;
    },
    logout() {
      this.$root.uid = "";
      this.$root.seclev = 0;
      this.$root.opcode = "";
      this.$root.tmpordno = "";
      this.$root.web = 1;
      this.drawer = false;
      this.$root.showlogo = 1;
      this.$router.push({
        name: "/landing",
      });
    },
    handleSelect(key) {
      // switch (key) {
      //   case "prodsch":
      if (key == "home") {
        this.$root.showlogo = 1;
      } else {
        this.$root.showlogo = 0;
      }
      this.$router.push({
        name: key,
        params: {
          opcode: this.$root.opcode,
          uid: this.$root.uid,
          showfooter: 1,
        },
      });
      this.drawer = false;
    },
  },
};
</script>
