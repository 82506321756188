import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

  const routes = [
    {
      path: "/ravioli",
      name: "ravioli",
      props: true,
      component: () => import("../components/ravioli.vue"),
    },
    {
      path: "/freshpasta",
      name: "freshpasta",
      props: true,
      component: () => import("../views/pastamenu.vue"),
    },
    {
      path: "/other",
      name: "other",
      props: true,
      component: () => import("../components/othermenu.vue"),
    },
    {
      path: "/",
      name: "/",
      props: true,
      component: () => import("../components/landing.vue"),
    },
     {
       path: "/landing",
       name: "home",
       props: true,
       component: () => import("../components/landing.vue"),
     },
    {
      path: "/terms",
      name: "terms",
      props: true,
      component: () => import("../components/termsandconditions.vue"),
    },
    {
      path: "/recipe",
      name: "recipe",
      props: true,
      component: () => import("../components/recipe.vue"),
    },
    {
      path: "/wholesale",
      name: "wholesale",
      props: true,
      component: () => import("../components/wholesale.vue"),
    },
    {
      path: "/stockists",
      name: "stockists",
      props: true,
      component: () => import("../components/stockists.vue"),
    },
    {
      path: "/aboutus",
      name: "aboutus",
      props: true,
      component: () => import("../components/aboutus.vue"),
    },
    {
      path: "/contactus",
      name: "contactus",
      props: true,
      component: () => import("../components/contactus.vue"),
    },
    {
      path: "/orderonline",
      name: "orderonline",
      props: true,
      component: () => import("../components/shoponline.vue"),
    },
    {
      path: "/shoponline",
      name: "shoponline",
      props: true,
      component: () => import("../components/shoponline.vue"),
    },
    {
      path: '*',
      component: () => import("../components/landing.vue"),
    }
  ]

  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })
  
  export default router
  
